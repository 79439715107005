<template>
  <div>
    <navigation></navigation>
    <!-- <img class="fxclass" src="../../assets/fx1.jpg" alt="" /> -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.fxclass {
  width: 100%;
}
</style>